.Sidebar {
    @media (max-width: 767px) {
    display: none;
      }
    width: 200px;
    height: 100vh;
    overflow: auto;
    box-shadow: rgba(16, 16, 16, 0.2) 0px 2px 8px 0px
}

.Sidebar ul {
    padding: 1.5rem 0;
    width: 100%;
}
.admin-header{
    font-size: 60px !important;
    font-weight: bold !important;
    color: #3ECD5E;
    text-align: center;
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
    background: -webkit-linear-gradient(#2ACB35, #333,#2ACB35);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* font-family: ; */
}
.admin-dash-card{
    box-shadow: 20px 20px 60px #bebebe,
    -20px -20px 60px #ffffff;
    border-radius: 50px;
    border-radius: 50px;
    width: 80%;
    height: auto;
    padding: 3rem;
    text-align: center;
    margin: auto;
}
.admin-dash-card h2{
    font-size: 60px !important;
    font-weight: bold !important;
}

.Sidebar ul li a {
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: #888888;
    border-bottom: 1px solid  #eee;
}

.Sidebar ul li .active {
    background-color: #2ACB35;
    color: #fff;
}

.Sidebar ul li a:hover {
    color: #fff;
    background-color: #2ACB35;
}

.Sidebar ul li a svg {
    margin-right: 0.5rem;

}

.admin_body {
    width: calc(100% - 200px);
    @media (max-width: 767px) {
     width: 100%;
          }
    padding: 1.5rem;

}

.admin_body form {
    padding: 1rem;
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: rgba(16, 16, 16, 0.2) 0px 2px 8px 0px
}

.admin_body form input {
    margin-right: 1rem;
 
}

.admin_body form .MuiTextField-root {
    width: 100%;
    @media (max-width: 767px) {
        padding: 0.5rem;
             }
  
}

.admin_body form button {
    margin: 1.5rem 0;
}

.admin_body form h1 {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 2rem;
}

 .topBar {
    width: 100%;
    padding: .75rem 2rem;
    border-bottom:1px solid #eee;
}

 .topBar img {
    height: 50px;
}
.css-azeo8y-MuiAvatar-root{
    background-color: #2ACB35 !important;
    width: 40px !important;
    height: 40px !important;
}


  .topBar .cmd{
    @media (max-width: 767px) {
        justify-content: space-between;
        flex-direction: row;      
        display: flex;

    } }
   
  

  /* Initially hide the button */
.admin_button {
    display: none;
  }
  
  /* Show the button when the screen width is at most 720px */
  @media (max-width: 767px) {
    .admin_name {
      display: none;
    }
  
    .admin_button {
      display: block;

    }
  }

  
  
